import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useLocation } from 'react-router-dom';
import { apiClient, apiUrl } from '../api';
import { useAuth } from '../context/AuthContext';
import { FilterComponent } from './FilterComponent';
// Modal files include
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

//  custom styling of the table.
const customStyles = {
  rows: {
    style: {
      minHeight: '70px', // override the row height
    },
  },
  table: {
    style: {
      border: '1px solid #EEEEEE', // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: '700',
    },
  },
  cells: {
    style: {
      fontSize: '16px',
      'div:first-child': {
        whiteSpace: 'unset!important',
      },
      img: {
        width: '70px',
        'object-fit': 'cover',
      },
    },
  },
  pagination: {
    style: {
      fontSize: '16px',
    },
  },
};
const Employees = () => {
  const location = useLocation();
  const { tokenValue } = useAuth();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(
    Number(new URLSearchParams(location.search).get('page')) || 1
  );
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [teamData, setTeamData] = useState();
  const [msg, setMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
  };

  const onOpenConfirmModal = () => setConfirmOpen(true);
  const onCloseConfirmModal = () => setConfirmOpen(false);

  const columns = [
    {
      name: '',
      selector: (row) => (
        <img src={apiUrl + '/' + row?.imgPath} alt={'Image of' + row?.name} />
      ),
      sortable: true,
      width: '90px',
    },
    {
      name: 'Name',
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row?.email,
    },
    {
      name: 'Designation',
      selector: (row) => row?.designation?.name,
      sortable: true,
    },
    {
      name: 'Team Name',
      selector: (row) => row?.teamInfo?.name,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row) => row.contactNumber,
    },
    {
      name: 'Edit',
      selector: (row) => (
        <div className='editTwoAnchor'>
          <Link
            to={`/edit-user/${row?._id}`}
            className='btn'
            state={currentPage}
          >
            Edit
          </Link>
          <a
            href={`${apiUrl}/${row?.nidPath}`}
            target='_blank'
            rel='noopener noreferrer'
            title='Download NID'
          >
            NID
          </a>
          {row?.tinPath && (
            <a
              href={`${apiUrl}/${row?.tinPath}`}
              target='_blank'
              rel='noopener noreferrer'
              title='Download TIN'
            >
              TIN
            </a>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setCurrentPage(
      Number(new URLSearchParams(location.search).get('page')) || 1
    );
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(
          `/emp/employees?page=${currentPage}&per_page=${perPage}&search=${filterText}&delay=1`,
          {
            headers: {
              authorization: `Bearer ${tokenValue}`,
            },
          }
        );
        const teamResult = await apiClient.get(`/team`, {
          headers: {
            authorization: `Bearer ${tokenValue}`,
          },
        });
        setTeamData(teamResult.data);
        setData(response.data.data.employeeDetails);
        setTotalRows(response.data.data.totalEmployee);
        setLoading(false);
      } catch (error) {
        console.log('🚀 ~ file: Employees.js:118 ~ fetchData ~ error:', error);
      }
    };
    fetchData();
  }, [currentPage, perPage, filterText, tokenValue]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    setCurrentPage(page);
    const response = await apiClient.get(
      `/emp/employees?page=${page}&per_page=${newPerPage}&search=${filterText}&delay=1`,
      {
        headers: {
          authorization: `Bearer ${tokenValue}`,
        },
      }
    );
    setData(response.data.data.employeeDetails);
    setTotalRows(response.data.data.totalEmployee);
    setLoading(false);
  };

  const handleResetLeaves = async () => {
    setLoading(true); // Show a loading spinner or disable the button

    try {
      const response = await apiClient.put(
        '/emp/reset-employee-leave',
        {}, // Empty body
        {
          headers: {
            authorization: `Bearer ${tokenValue}`, // Authorization header
          },
        }
      );

      // Display the success message in the modal
      setMsg(response.data.msg);
      onOpenModal();
    } catch (error) {
      // Handle errors by setting an error message
      setMsg(error.response?.data?.msg || 'An unexpected error occurred.');
      onOpenModal();
    } finally {
      setLoading(false); // Re-enable the button or hide the spinner
    }
  };

  const confirmResetLeaves = () => {
    handleResetLeaves();
    onCloseConfirmModal();
  };

  const subHeaderComponentMemo = useMemo(() => {
    const inputHandler = (e) => {
      setFilterText(e.target.value);
    };
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={inputHandler}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleSelectChange = async (event) => {
    setFilterText(event.target.value);
  };

  return (
    <>
      <div className='headingWithBtn'>
        <h1>Employee Management</h1>
        <div className='employee-btn-container'>
          <Link to={'/add-static-email'} className='btn btn-primary me-10'>
            Add Static Email
          </Link>
          <Link to={'/addUser'} className='btn btn-primary me-10'>
            Add Employee
          </Link>
          <button onClick={onOpenConfirmModal} className='btn btn-primary'>
            Reset Employee Leave
          </button>
        </div>
      </div>
      <div className='sortByTeam book__form'>
        <h2>Sort By Team</h2>
        <div className='form-item'>
          <select
            name='sortByTeam'
            id='sortByTeam'
            onChange={handleSelectChange}
            value={filterText}
          >
            <option value=''>Select Team</option>
            {teamData?.map((value, index) => (
              <option key={index} value={value.name}>
                {value.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        paginationDefaultPage={currentPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Rows per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false,
          selectAllRowsItem: true,
          selectPerPageItem: false,
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        progressPending={loading}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        customStyles={customStyles}
        highlightOnHover
      />
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: 'customModal',
        }}
        animationDuration={800}
      >
        <p>{msg}</p>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        open={confirmOpen}
        onClose={onCloseConfirmModal}
        center
        classNames={{
          modal: 'customModal',
        }}
        animationDuration={800}
      >
        <p>Are you sure you want to reset all the employee leaves?</p>
        <div className='d-flex confirmModal'>
          <button onClick={confirmResetLeaves} className='btn-danger'>
            Yes
          </button>
          <button onClick={onCloseConfirmModal} className='btn-primary'>
            No
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Employees;
